<template>
  <div class="home">
    <h1>Home Page</h1>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
};
</script>
